/*
 * AFFICHAGE DES PHOTOS ET VIDEOS
 */
// controle sur la video Vimeo
function manageVideo() {
    if ($('.videoVimeo iframe').length > 0) {
        var player = $('.videoVimeo iframe');
        var playerOrigin = '*';
        // Handle messages received from the player
        function onMessageReceived(event) {
            // Handle messages from the vimeo player only
            if (!(/^https?:\/\/player.vimeo.com/).test(event.origin)) {
                return false;
            }
            if (playerOrigin === '*') {
                playerOrigin = event.origin;
            }
            var data = JSON.parse(event.data);
            switch (data.event) {
                case 'ready':
                    onReady();
                    break;
            }
        }

        // Helper function for sending a message to the player
        function post(action, value) {
            var data = {
                method: action
            };
            if (value) {
                data.value = value;
            }
            var message = JSON.stringify(data);
            player[0].contentWindow.postMessage(data, playerOrigin);
        }

        function onReady() {
            post('addEventListener', 'pause');
            post('addEventListener', 'finish');
            post('addEventListener', 'playProgress');
        }

        // Listen for messages from the player
        if (window.addEventListener) {
            window.addEventListener('message', onMessageReceived, false);
        }
        else {
            window.attachEvent('onmessage', onMessageReceived, false);
        }
        $('.videoVimeo .seekMenu li a').click(function () {
            convertTimecodeInSecond($(this).find('time').text());
            post("seekTo", top.seekTime);
        });
    }
    // controle sur la video Youtube
    if ($('.videoYoutube').length > 0) {
        // ajout du wmode dans l'url pour pouvoir passer des elements du DOM par dessus la video
        $('.videoYoutube iframe').each(function () {
            var srcAttr = $(this).attr('src').replace('?', '?wmode=transparent&');
            $(this).attr('src', srcAttr);
        });
        // chapitrages
        $('.videoYoutube .seekMenu li a').click(function () {
            convertTimecodeInSecond($(this).find('time').text());
            player.seekTo(top.seekTime);
            return false;
        });
    }
    // controle sur la video Dailymotion
    if ($('.videoDailymotion').length > 0) {
        var dmPlaying = 0;
        DM.init({
            apiKey: dmApiKey,
            status: true, // check login status
            cookie: true // enable cookies to allow the server to access the session
        });
        DM.api('/video/' + dmID, function () {
            var dmPlayer = DM.player(document.getElementById('dmPlayer'), {video: dmID, width: dmWidth, height: dmHeight, title: dmTitle});
            dmPlayer.addEventListener("playing", function () {
                dmPlaying = 1;
            });
            $('.videoDailymotion .seekMenu li a').click(function () {
                convertTimecodeInSecond($(this).find('time').text());
                if (dmPlaying == 0) {
                    dmPlayer.play();
                    dmPlayer.addEventListener("playing", function () {
                        dmPlayer.seek(top.seekTime);
                        dmPlaying = 1;
                    });
                } else {
                    dmPlayer.seek(top.seekTime);
                }
                return false;
            });
        });
    }
}
