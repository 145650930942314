// Add class
const addClass = function (el, className) {
    if (el.classList) {
        el.classList.add(className) // IE 10+
    }
    else {
        el.className += ' ' + className // IE 8+
    }
}

// Add removeClass
const removeClass = function (el, className) {
    //console.log(el.classList);
    if (el.classList) {
        el.classList.remove(className) // IE 10+
    }
    else {
        el.className = el.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ') // IE 8+
    }
}


// Element has class
const hasClass = function (el, className) {
    if (el.classList) {
        return el.classList.contains(className) // IE 10+
    }
    else {
        return new RegExp('(^| )' + className + '( |$)', 'gi').test(el.className) // IE 8+ ?
    }
}

// set attributes
/*
 * setAttributes(index, {
 *     'role' : 'button',
 *     'aria-controls' : 'id_123456'
 * })
 *
 * */
const setAttributes = function (node, attrs) {
    Object
        .keys(attrs)
        .forEach(function (attribute) {
            node.setAttribute(attribute, attrs[attribute])
        })
}

// Height : elem + padding + margin + border
function outerHeight(el) {
    var height = el.offsetHeight
    var style = getComputedStyle(el)

    height += parseInt(style.marginTop) + parseInt(style.marginBottom)
    return height
}

// Width : elem + padding + margin + border
function outerWidth(el) {
    var width = el.offsetWidth
    var style = getComputedStyle(el)

    width += parseInt(style.marginLeft) + parseInt(style.marginRight)
    return width
}

// https://plainjs.com/javascript/traversing/get-siblings-of-an-element-40/
function getSiblings(el, filter) {
    var siblings = [];
    el = el.parentNode.firstChild;
    do {
        if (!filter || filter(el)) siblings.push(el);
    } while (el = el.nextSibling);
    return siblings;
}

// http://jsfiddle.net/8RfbT/69/
function findAncestor(el, cls) {
    while ((el = el.parentElement) && !el.classList.contains(cls));
    return el;
}
