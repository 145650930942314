function customTabs() {
    $(".offre__tabs-btn a").click(function (event) {
        event.preventDefault();
        $(this).parent().addClass("current");
        $(this).parent().siblings().removeClass("current");
        var tab = $(this).attr("href");
        $(".offre__tabs-item").not(tab).css("display", "none");
        $(tab).fadeIn();
    });
}
