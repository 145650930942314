// Caroussel3
var carouselWrap3 = $('.carrousel3'),
    carousel3 = $('.listItems', carouselWrap3),
    reinitTimer3;

function carrousel3(itemVisible) {
    if (carouselWrap3.length > 0) {
        var nbItem = $('.item', carousel3).length,
            nbItemConfig;

        if (itemVisible) {
            nbItemConfig = itemVisible;
        } else {
            nbItemConfig = '6';
        }

        if (nbItem > nbItemConfig) {
            if ($('.prevNextCaroussel3').length == 0) {
                carousel3.parent().append('<ul class="prevNextCarrousel3">' +
                    '<li class="prevCarrousel3"><button type="button" class="button-reset">' + l10n['prev'] + '</button></li>' +
                    '<li class="nextCarrousel3"><button type="button" class="button-reset">' + l10n['next'] + '</button></li>' +
                    '</ul>');
            }

            carousel3.cycle({
                log:             false,
                fx:              'carousel',
                carouselVisible: nbItemConfig,
                carouselFluid:   true,
                swipe:           true,
                timeout:         0,
                prev:            '.prevCarrousel3 button',
                next:            '.nextCarrousel3 button',
                slides:          '.item',
                autoHeight:      'calc'
            });

            $('.item', carousel3).css('opacity', '1');
        }
    }
}

var destroyCarrousel3 = function () {
    $('.carrousel3 .listItems').cycle('destroy');
}

function reinit_cycle3(nbItem) {
    setTimeout(function () {
        destroyCarrousel3();
        setTimeout(function () {
            carrousel3(nbItem);
        }, 200);
    }, 200);
}

function carrousel3State(view) {
    if (view === 'mediaDesktop') {
        clearTimeout(reinitTimer3);
        reinitTimer3 = setTimeout(reinit_cycle3('3'), 200);
    } else if (view === 'mediaTablet') {
        clearTimeout(reinitTimer3);
        reinitTimer3 = setTimeout(reinit_cycle3('2'), 200);
    } else if (view === 'mediaSmartphone') {
        clearTimeout(reinitTimer3);
        reinitTimer3 = setTimeout(reinit_cycle3('1'), 200);
    }
}
