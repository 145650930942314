/*
 <section class="caroussel1">
 <div class="listItems">
 <div class="item" style="background-image: url('../Images/temp/caroussel1.jpg');">
 <div class="wrapper">
 <h2 class="title"><a href="#" class="linkBlock">1Duis sed odio sit amet nibh vulputate cursus a sit amet mauris.</a></h2>
 <p class="moreInfos"><a href="#">Découvrir l'école</a></p>
 </div>
 </div>
 <div class="item" style="background-image: url('../Images/temp/caroussel2.jpg');">
 <div class="wrapper">
 <h2 class="title">2Duis sed odio sit amet nibh vulputate cursus a sit amet mauris.</h2>
 <p class="moreInfos"><a href="#">Découvrir l'école</a></p>
 </div>
 </div>
 </div>
 </section>
 */

// Caroussel1
function carrousel1Desktop() {
    var carrouselWrap = $('.carrousel1');
    if (carrouselWrap.length > 0) {
        var carrousel = $('.listItems', carrouselWrap);

        if ($('.buttonStop').length == 0) {
            carrousel.parent().append('<button type="button" class="button-reset buttonStop modeplay"><span></span>' + l10n['stopCarrousel'] + '</button>');
            carrousel.parent().append('<button type="button" class="button-reset buttonStart modeplay modeplay--pause"><span></span>' + l10n['startCarrousel'] + '</button>');
        }
        if ($('.prevNextCarrousel1').length == 0) {
            carrousel.parent().append('<ul class="prevNextCarrousel1">' +
                '<li class="prevCarrousel1"><button type="button" class="button-reset">' + l10n['prev'] + '</button></li>' +
                '<li class="nextCarrousel1"><button type="button" class="button-reset">' + l10n['next'] + '</button></li>' +
                '</ul>');
        }
        if ($('.pagerCarrousel1').length == 0) {
            carrousel.parent().append('<ul class="pagerCarrousel1"></ul>');
        }


        var buttonStop = $('.buttonStop', carrouselWrap);
        var buttonStart = $('.buttonStart', carrouselWrap);

        // Gestion des items actifs sur le pager A11Y
        $(document, carrousel).on('cycle-update-view', function (e, opts, slideOpts) {
            var pagers;
            if (opts.pager) {
                pagers = opts.API.getComponent('pager');
                pagers.each(function () {
                    var titlePager = $(this).children().eq(opts.currSlide).find('button img').attr('alt');

                    // Class active
                    $(this).children().find('li').removeClass(opts.pagerActiveClass).eq(opts.currSlide).addClass(opts.pagerActiveClass);

                    // Active title button
                    $(this).children().find('button').removeAttr('title');
                    $(this).children().find('button img').attr('src', pathImg + 'carrousel1/pager-off.png');
                    $(this).children().eq(opts.currSlide).find('button').attr('title', titlePager + ' - ' + l10n['activeSlide']);
                    $(this).children().eq(opts.currSlide).find('button img').attr('src', pathImg + 'carrousel1/pager-on.png');
                });
            }
        });

        // Gestion du pager
        $(document, carrousel).on('cycle-bootstrap', function (e, opts, API) {
            API.buildPagerLink = function (opts, slideOpts, slide) {
                var pagerLink;
                var pagers = opts.API.getComponent('pager');
                pagers.each(function () {
                    var pager = $(this);
                    var markup = $('<li></li>').html('<button type="button" class="button-reset"><img src="' + pathImg + 'carrousel1/pager-off.png" alt="' + $('.title', slide).text() + '" /></button>');
                    pagerLink = $(markup).appendTo(pager);
                    pagerLink.on(opts.pagerEvent, function (e) {
                        e.preventDefault();
                        opts.API.page(pager, e.currentTarget);
                    });
                });
            };
        });

        carrousel.cycle({
            log:     false,
            fx:      'fade',
            speed:   '600',
            timeout: 4000,
            prev:    '.prevCarrousel1 button',
            next:    '.nextCarrousel1 button',
            slides:  '.item',
            //autoHeight:	'460:345',
            pager:   '.pagerCarrousel1'
        });

        //carousel.cycle('pause');

        // Arret de l'animation
        var animActive = true;

        function pauseAnim() {
            carrousel.cycle("pause");
            // buttonStop.addClass('modeplay--pause').html('<span></span>' + l10n['startCarrousel']);
            animActive = false;
        }

        function resumeAnim() {
            carrousel.cycle("resume");
            // buttonStop.removeClass('modeplay--pause').html('<span></span>' + l10n['stopCarrousel']);
            animActive = true;
        }

        function resumePauseAnimation(etat) {
            if (etat === "pause") {
                pauseAnim();
            } else if (etat === "resume") {
                resumeAnim();
            }
            else {
                if (animActive === true) {
                    pauseAnim();
                } else {
                    resumeAnim();
                }
            }
        }

        $('.nextCarrousel1 button').click(function () {
            pauseAnim();
        });
        $('.prevCarrousel1 button').click(function () {
            pauseAnim();
        });
        $('.pagerCarrousel1 button').click(function () {
            pauseAnim();
        });
        buttonStop.click(function () {
            pauseAnim();
        });
        buttonStart.click(function () {
            resumeAnim();
        });
    }
}

function carrousel1State(view) {
    if ((view === 'mediaDesktop') || (view === 'mediaTablet') || (view === 'mediaSmartphone')) {
        carrousel1Desktop();
    }
}
