function accessTooltipState() {
    AccessTooltip({
        objs:             '.access__title',
        tooltipClassName: 'access-tooltip',
        toolTipBetween:   5,
        toolTipUp:        false,
        mouse:            true,
        tempDelay:        4000,
        useAriaDP:        false,
        useEscClose:      true
    });
}
