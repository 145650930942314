var geocodeButtonId = 'geocode-button';
var geocodeInput = '.geocode input[type="text"]';

var apiGeolocationSuccess = function (position) {
    $.get('http://maps.googleapis.com/maps/api/geocode/json?latlng=' + position.coords.latitude + ',' + position.coords.longitude + '&sensor=true', function (response) {
        if (response) {
            $(geocodeInput).val(response.results[0]['formatted_address']);
            $(geocodeInput).trigger('change');
        } else {
            alert('Votre position n\'est pas reconnue.');
        }
    });
};

var tryAPIGeolocation = function () {
    jQuery.post("https://www.googleapis.com/geolocation/v1/geolocate?key=AIzaSyDCa1LUe1vOczX1hO_iGYgyo8p_jYuGOPU", function (response) {
        apiGeolocationSuccess({coords: {latitude: response.location.lat, longitude: response.location.lng}});
    })
    .fail(function (err) {
        alert("Erreur de géolocalisation :\n\n" + err);
    });
};

var browserGeolocationSuccess = function (position) {
    apiGeolocationSuccess({coords: {latitude: position.location.lat, longitude: position.location.lng}});
};

var browserGeolocationFail = function (error) {
    switch (error.code) {
        case error.TIMEOUT:
            alert("Votre géolocalisation prend trop de temps. Veuillez recommencer ultérieurement.");
            break;
        case error.PERMISSION_DENIED:
            if (error.message.indexOf("Only secure origins are allowed") === 0) {
                tryAPIGeolocation();
            }
            break;
        case error.POSITION_UNAVAILABLE:
            alert("La géolocalisation n'est pas disponible sur votre navigateur. Rechargez la page et autorisez la géolocalisation lorsqu'elle vous sera demandées.");
            break;
    }
};

var tryGeolocation = function () {
    if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
            browserGeolocationSuccess,
            browserGeolocationFail,
            {maximumAge: 50000, timeout: 20000, enableHighAccuracy: true});
    }
    else {
        alert('Votre navigateur ne prend pas en charge la géolocalisation. Veuillez le mettre à jour ou utiliser un navigateur plus récent.');
    }
};

function geocode() {
    $(geocodeInput).each(function () {
        var $this = $(this);
        $this.wrap('<div class="geocode__wrapper"></div>').parent().append('<button id="' + geocodeButtonId + '" type="button" class="button-1 button-1--small button-1--no-text geocode__wrapper-button" title="Déterminer mon adresse actuelle">Déterminer mon adresse actuelle</button>');
        $('#' + geocodeButtonId).on('click', function () {
            tryGeolocation();
        });
    });
}
