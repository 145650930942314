// Feature detect + local reference
var storage = (function () {
    var uid = new Date;
    var storage;
    var result;
    try {
        (storage = window.localStorage).setItem(uid, uid);
        result = storage.getItem(uid) == uid;
        storage.removeItem(uid);
        return result && storage;
    } catch (exception) {
    }
}());

// send var in URL
jQuery.extend({
    getUrlVars: function () {
        var vars = [], hash;
        var hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
        for (var i = 0; i < hashes.length; i++) {
            hash = hashes[i].split('=');
            vars.push(hash[0]);
            vars[hash[0]] = hash[1];
        }
        return vars;
    },
    getUrlVar:  function (name) {
        return jQuery.getUrlVars()[name];
    }
});

// preload images
function preload(arrayOfImages) {
    $(arrayOfImages).each(function () {
        $('<img/>')[0].src = this;
    });
}

// Check date format
function isDate(txtDate) {
    var currVal = txtDate;
    // Declare Regex
    var rxDatePattern = /^(\d{1,2})(\/|-)(\d{1,2})(\/|-)(\d{4})$/;
    var dtArray = currVal.match(rxDatePattern); // is format OK?

    if (dtArray == null)
        return false;

    //Checks for dd/mm/yyyy format
    var dtDay = dtArray[1];
    var dtMonth = dtArray[3];
    var dtYear = dtArray[5];

    if (dtMonth < 1 || dtMonth > 12)
        return false;
    else if (dtDay < 1 || dtDay > 31)
        return false;
    else if ((dtMonth == 4 || dtMonth == 6 || dtMonth == 9 || dtMonth == 11) && dtDay == 31)
        return false;
    else if (dtMonth == 2) {
        var isleap = (dtYear % 4 == 0 && (dtYear % 100 != 0 || dtYear % 400 == 0));
        if (dtDay > 29 || (dtDay == 29 && !isleap))
            return false;
    }
    return true;
}

// Display alert if form field is not empty
function alertErrorForm(form, field) {
    $(form + ' button').click(function () {
        var formError = false;
        var inputVal = $(field).val();
        if (inputVal == '') {
            alert(l10n['formErrorMandatoryField']);
            formError = true;
        }
        if (formError == true) {
            return false;
        }
    });
}

// get var to url
$.urlParam = function (name, url) {
    var results = new RegExp('[\\?&amp;]' + name + '=([^&amp;#]*)').exec(url);
    return results[1] || 0;
};

// error handling
function addErrorMessage($object, errorPrefix) {
    // Default value for errorPrefix
    if (!errorPrefix) {
        var errorPrefix = 'Erreur : ';
    }
    $object.html(errorPrefix.concat($object.html()));
}
if ($('.tx-powermail-pi1_mandatory').length > 0) {
    addErrorMessage($('title'));
    addErrorMessage($('.rte h1'));
}
if ($('#navigationError').length > 0) {
    addErrorMessage($('title'), l10n['navigationError'] + ' : ');
    addErrorMessage($('.rte h1'), l10n['navigationError'] + ' : ');
}

// Transform a date in timestamp (date format is dd-mm-yyyy)
function convertDateInTimestamp(date) {
    var splittedDate = date.split('-');
    var convertedDate = new Date(splittedDate[2], parseInt(splittedDate[1], 10) - 1, splittedDate[0]);
    return convertedDate.getTime() / 1000;
}

// Transform a hour in second (hour format is hh:mm)
function convertTimeInSecond(time) {
    var splittedTime = time.split(':');
    var convertedTime = (+splittedTime[0]) * 60 * 60 + (+splittedTime[1]) * 60;
    return convertedTime;
}

// Transform timecode in second
function convertTimecodeInSecond(timecodeConteneur) {
    var time = timecodeConteneur;
    if (time.split(/:/g).length - 1 == 2) {
        var elem = time.split(/:/g);
        var hour = elem[0] * 3600;
        var minute = elem[1] * 60;
        var second = elem[2];
        top.seekTime = parseInt(hour) + parseInt(minute) + parseInt(second);
    }
    if (time.split(/:/g).length - 1 == 1) {
        var elem = time.split(/:/g);
        var minute = elem[0] * 60;
        var second = elem[1];
        top.seekTime = parseInt(minute) + parseInt(second);
    }
}

// check if really NaN
var reallyIsNaN = function (x) {
    return x !== x;
};

// check if url exist
function urlExists(url) {
    var request = new XMLHttpRequest();
    request.open('GET', url, true);
    request.onreadystatechange = function () {
        if (request.readyState === 4) {
            return (request.status !== 404);
        }
    };
    request.send();
}

// load external script
function loadScript(url, callback) {
    // Adding the script tag to the head as suggested before
    var head = document.getElementsByTagName('head')[0];
    var script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = url;

    // Then bind the event to the callback function.
    // There are several events for cross browser compatibility.
    script.onreadystatechange = callback;
    script.onload = callback;

    // Fire the loading
    head.appendChild(script);
}
