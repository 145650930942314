// Caroussel2
var carouselWrap2 = $('.carrousel2'),
    carousel2 = $('.listItems', carouselWrap2),
    reinitTimer2;

function carrousel2(itemVisible) {
    if (carouselWrap2.length > 0) {
        var nbItem = $('.item', carousel2).length,
            nbItemConfig;

        if (itemVisible) {
            nbItemConfig = itemVisible;
        } else {
            nbItemConfig = '6';
        }

        if (nbItem > nbItemConfig) {
            if ($('.prevNextCaroussel2').length == 0) {
                carousel2.parent().append('<ul class="prevNextCarrousel2">' +
                    '<li class="prevCarrousel2"><button type="button" class="button-reset">' + l10n['prev'] + '</button></li>' +
                    '<li class="nextCarrousel2"><button type="button" class="button-reset">' + l10n['next'] + '</button></li>' +
                    '</ul>');
            }

            carousel2.cycle({
                log:             false,
                fx:              'carousel',
                carouselVisible: nbItemConfig,
                carouselFluid:   true,
                swipe:           true,
                timeout:         0,
                prev:            '.prevCarrousel2 button',
                next:            '.nextCarrousel2 button',
                slides:          '.item',
                autoHeight:      'calc'
            });

            $('.item', carousel2).css('opacity', '1');
        }
    }
}

var destroyCarrousel2 = function () {
    $('.carrousel2 .listItems').cycle('destroy');
}

function reinit_cycle2(nbItem) {
    setTimeout(function () {
        destroyCarrousel2();
        setTimeout(function () {
            carrousel2(nbItem);
        }, 200);
    }, 200);
}

function carrousel2State(view) {
    if (view === 'mediaDesktop') {
        clearTimeout(reinitTimer2);
        reinitTimer2 = setTimeout(reinit_cycle2('4'), 200);
    } else if (view === 'mediaTablet') {
        clearTimeout(reinitTimer2);
        reinitTimer2 = setTimeout(reinit_cycle2('4'), 200);
    } else if (view === 'mediaSmartphone') {
        clearTimeout(reinitTimer2);
        reinitTimer2 = setTimeout(reinit_cycle2('1'), 200);
    }
}
